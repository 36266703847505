import React, { useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Logo from '../../assets/images/logo.png';
import { useMainContext } from "../../context/mainContext";
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { AppBar, Avatar, Box, Button, ButtonGroup, Chip, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import ETHIcon from '../../assets/images/coin/eth.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LinearGradient } from "@amcharts/amcharts5";
import FaceIcon from '@mui/icons-material/Face';
import axios from "axios";
import { socket } from "../../utils/socket";
import {Link} from "@mui/material";
import { useNavigate } from "react-router-dom";

const options = ['Ethereum', 'BSC'];

function ElevationScroll({ children, window }: { children: React.ReactElement, window?: () => Window }) {
    // Use useScrollTrigger to respond to user scroll
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0, // Adjust elevation based on scroll. You can adjust this value.
        style: {
            backgroundColor: '#1C2132', // Change background color on scroll
            transition: trigger ? 'background-color 0.3s, box-shadow 0.3s' : 'none', // Smooth transition for the background change
        },
    });
}

function Header({ drawerWidth, elevation }: { drawerWidth: number, elevation?: number }) {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const { handleDrawerToggle } = useMainContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [latestTokens, setLatestTokens] = React.useState<any[]>([]);
    const [latestAction, setLatestAction] = React.useState<string[]>([]);
    let navigate = useNavigate();
    const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setIsOpen(false);
    };

    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setIsOpen(false);
    };

    const getLatestTrade = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}`)
                .then(function (response) {
                    setLatestTokens(response.data);
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
    }    

    React.useEffect(() => {
        getLatestTrade();
    }, []);

    React.useEffect(() => {
        socket.on("message", (data:any) => {
           getLatestTrade();
        })

        return () => {
            socket.off("message");
        }
    }, []);


    return (
        <div className="Header">
            <ElevationScroll>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        background: '1C2132',
                        boxShadow: 'none'
                    }}
                >
                    <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Box display={"flex"}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                sx={{ display: { sm: 'none' } }}
                            >
                                <img src={Logo} height={35} alt="logo" />
                            </IconButton>
                        </Box>
                        <Box sx={{ width: '90%', overflow: 'hidden', display: { sm: 'inline-flex', xs: 'none' } }}>
                            {latestTokens?.map((item: any, index: number) => 
                                <a href={`${item.network}/${item.tokenAddress}`} style={{ marginRight: '1rem', background: '#ffffff29', padding: '0.25rem', borderRadius: '2rem', textDecoration:'none', color:'white' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ width: 25, height: 25, mr: '0.5rem' }} alt="Natacha" src={`https://api.multiavatar.com/${index}.png`} />
                                        <Typography sx={{textWrap:'nowrap'}}>{`${item.type} ${item.ethAmount} ${item.network == "Base" ? "BaseETH" : item.network == "Sepolia" ? "SepoliaETH" : item.network == "Avax" ?  "Avax" : item.network == "Arbitrum" ? "Arb" : "ETH"} of ${item.tokenName}`}</Typography>
                                        <Avatar sx={{ width: 25, height: 25, ml: '0.5rem', textWrap:'nowrap' }} alt="Natacha" src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.tokenImage}`} />
                                    </Box>
                                </a>
                            )}
                        </Box>
                        <Box marginLeft='auto' display={"flex"}>
                            {isConnected && <Button variant="contained" style={{ backgroundImage: "linear-gradient(90deg, #FFA800 14.14%, #9E1F63 100%)", color: 'white', borderBottomRightRadius: 20, borderTopRightRadius: 5, borderTopLeftRadius: 20, paddingLeft: '2rem', paddingRight: '2rem' }} onClick={() => open()}>{`${address?.slice(0, 5)}....${address?.slice(-4)}`}</Button>}
                            {!isConnected && <Button variant="contained" style={{ backgroundImage: "linear-gradient(90deg, #FFA800 14.14%, #9E1F63 100%)", color: 'white', borderBottomRightRadius: 20, borderTopRightRadius: 5, borderTopLeftRadius: 20, paddingLeft: '2rem', paddingRight: '2rem' }} onClick={() => open()}>Connect</Button>}
                        </Box>
                        <Box marginLeft='auto' display={"flex"}>
                            {(address?.toLowerCase() == ownerAddress?.toLowerCase()) && <Button variant="contained" style={{ backgroundImage: "linear-gradient(90deg, #6928d5 14.14%, #9E1F63 100%)", marginLeft: '1rem', color: 'white', borderBottomRightRadius: 20, borderTopRightRadius: 5, borderTopLeftRadius: 20, paddingLeft: '2rem', paddingRight: '2rem' }} onClick={() => navigate('/admin')}>Admin</Button>}
                        </Box>
                    </Toolbar>
                    <Toolbar sx={{ display: { xs: latestTokens.length > 0 ? 'flex' : 'none', sm: 'none' }, flexDirection: 'row', justifyContent: "space-between", borderRadius:150 }}>
                        <Box sx={{ width: '100%', overflow: 'hidden', display: { sm: 'none', xs: 'inline-flex' } }}>
                        {latestTokens.map((item: any, index: number) => (
                                <a href={`${item.network}/${item.tokenAddress}`} style={{ marginRight: '1rem', background: '#ffffff29', padding: '0.25rem', borderRadius: '2rem', textDecoration:'none', color:'white' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ width: 25, height: 25, mr: '0.5rem' }} alt="Natacha" src={`https://api.multiavatar.com/${index}.png`} />
                                        <Typography sx={{textWrap:'nowrap'}}>{`${item.type} ${item.ethAmount} ${item.network} of ${item.tokenName}`}</Typography>
                                        <Avatar sx={{ width: 25, height: 25, ml: '0.5rem',  }} alt="Natacha" src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${item.tokenImage}`} />
                                    </Box>
                                </a>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </div>
    );
}

export default Header;
