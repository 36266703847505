import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputAdornment, List, ListItem, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tab, Tabs, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { Link, useParams } from "react-router-dom";
import AvaxIcon from '../assets/images/coin/avax.png';
import ArbitrumIcon from "../assets/images/coin/Arbitrum.svg";
import BaseIcon from '../assets/images/coin/Coinbase.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainCard from "../components/cards";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomPaginationActionsTable from "../components/table";
import axios from "axios";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from "@web3modal/ethers/react";
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { socket } from "../utils/socket";
import { useEffect, useState } from "react";
import { BrowserProvider, Contract, Eip1193Provider, ethers, formatUnits } from 'ethers';
import fairLaunchFactoryABI from "../abis/FairLaunchFactoryABI";
import FairLaunchFactoryAvalancheABI from "../abis/FairLaunchFactoryAvalancheABI";
import {SEPOLIA_LP_CONTRACT_ADDRESS, ETHEREUM_LP_CONTRACT_ADDRESS, AVAX_LP_CONTRACT_ADDRESS, ARBITRUM_LP_CONTRACT_ADDRESS, BASE_LP_CONTRACT_ADDRESS} from "../abis/FairLaunchFactoryAddress";

const totalSupply = 10 ** 9;

export const getContractABI = (network: string | undefined, chainIdParam: number | undefined = 0) => {
    if (network == "BychainId") {
        switch(chainIdParam) {
            case 43114: // Avax
                return  FairLaunchFactoryAvalancheABI.abi;
            case 42161: // Arbitrum
            case 8453: // base
            case 11155111: // sepolia
            case 1: // Ethereum
                return  fairLaunchFactoryABI.abi; 
            default:
                return fairLaunchFactoryABI.abi;
        }
    } else {
        switch(network) {
            case "Avax":
                return  FairLaunchFactoryAvalancheABI.abi;
            case "Base":
            case "Ethereum":
            case "Arbitrum":
            case "Sepolia":
                return  fairLaunchFactoryABI.abi;
            default:
                return fairLaunchFactoryABI.abi; 
        }
    }
}

export const getContractAddress = (network: string | undefined, chainIdParam: number | undefined = 0) => {
    let realAddress = "";
    if (network == "BychainId") {
        switch(chainIdParam) {
            case 43114: // Avax
                realAddress = AVAX_LP_CONTRACT_ADDRESS;
                break;        
            case 42161:
                realAddress = ARBITRUM_LP_CONTRACT_ADDRESS;
                break;
            case 8453:
                realAddress = BASE_LP_CONTRACT_ADDRESS;
                break;   
            case 11155111:
                realAddress = SEPOLIA_LP_CONTRACT_ADDRESS;
                break;  
            case 1:
                realAddress = ETHEREUM_LP_CONTRACT_ADDRESS;
                break;           
            default:
                break;
        }
    } else {
        switch(network) {
            case "Base":
                realAddress = BASE_LP_CONTRACT_ADDRESS;
                break;
            case "Avax":
                realAddress = AVAX_LP_CONTRACT_ADDRESS;
                break;
            case "Arbitrum":
                realAddress = ARBITRUM_LP_CONTRACT_ADDRESS;
                break;
            case "Sepolia":
                realAddress = SEPOLIA_LP_CONTRACT_ADDRESS;
                break;
            case "Ethereum":
                realAddress = ETHEREUM_LP_CONTRACT_ADDRESS;
                break;   
            default:
                break;  
        }
    }

    return realAddress;
}
export const getHref = (chainName: string | undefined, url: string, hash: string = "") => {
    let realUrl = "";
    if (!chainName) return "";
    if (chainName.toLowerCase() === "ethereum") realUrl = `https://etherscan.io/${hash == "hash"? "tx" : "address"}/${url}`
    else if (chainName.toLowerCase() === "sepolia") realUrl = `https://sepolia.etherscan.io/${hash == "hash"? "tx" : "address"}/${url}`
    else if (chainName.toLowerCase() === "base") realUrl = `https://basescan.org/${hash == "hash"? "tx" : "address"}/${url}`
    else if (chainName.toLowerCase() === "arbitrum") realUrl = `https://arbiscan.io/${hash == "hash"? "tx" : "address"}/${url}`
    else if (chainName.toLowerCase() === "avax") realUrl = `https://snowtrace.io/${hash == "hash"? "tx" : "address"}/${url}`
    return realUrl;
}

const tokenABI = [
    // Minimal ABI for ERC-20 Token Balance
    "function balanceOf(address owner) view returns (uint256)",
    "function launched() view returns (bool)"
];

export default function DetailPage() {
    const { chain, id } = useParams();
    const [detailData, setDetailData] = React.useState<any>(null);
    const [chatData, setChatData] = React.useState<any>([]);
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [chartData, setChartData] = React.useState<any[]>([]);
    const [value, setValue] = React.useState('1');
    const [holders, setHolders] = React.useState([]);
    const [amount, setAmount] = React.useState(0);
    const [type, setType] = React.useState("BUY");
    const [estimateAmount, setEstimateAmount] = React.useState('0');
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [lpBalance, setLPBalance] = React.useState(0);
    const [maxAmount, setMaxAmount] = React.useState('0');
    const [launched, setLaunched] = React.useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { switchNetwork } = useSwitchNetwork()

    const [signer, setSigner] = React.useState(null as any);
    const [fairLaunchContract, setFairLaunchContract] = React.useState(null as any);
    const [nativeBalanceString, setNativeBalanceString] = React.useState("");
    const infuraProjectId = "6cdb70a0884b4131a80a7fec5289acec";

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const getRpcUrl = (detailDataParam: any) => {
        if (!detailDataParam) return;
        let url = `https://sepolia.infura.io/v3/${infuraProjectId}`;
        switch(detailDataParam.network) {
            case "Base":
                url = `https://base.blockpi.network/v1/rpc/public`;
                break;
            case "Avax":
                url = `https://avalanche-mainnet.infura.io/v3/${infuraProjectId}`;
                break;
            case "Arbitrum":
                url = `https://arbitrum-mainnet.infura.io/v3/${infuraProjectId}`;
                break;
            case "Sepolia":
                url = `https://sepolia.infura.io/v3/${infuraProjectId}`;
                break;
            case "Ethereum":
                url = `https://mainnet.infura.io/v3/${infuraProjectId}`;
                break;   
            default:
                break;  
        }
        return url;
    }
    const setInput = (e: any) => {
        if (!e.target.value) setAmount(0);
        else setAmount(e.target.value);
    }
    const showOutput = async(val: any) => {
        if (!detailData) return;
        let inputAmount = val;
        if (!inputAmount) inputAmount = "0";

        const rpcUrl = getRpcUrl(detailData);
        const tokenProvider = new ethers.JsonRpcProvider(rpcUrl);
        const realAddress = getContractAddress(detailData.network);
        const realABI = getContractABI(detailData.network);
        const tempFairLaunchContract = new ethers.Contract(realAddress, realABI, tokenProvider);

        const tokenPriceBigInt = BigInt(await tempFairLaunchContract.getPrice(detailData?.tokenAddress));
        const realInputAmount = parseFloat(inputAmount) * 97 / 100; // 3% Fee of Buy & Sell

        let outputAmount = 0; // This will now be a float

        if (type === "BUY") {
            const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it
            outputAmount = Number(amountInWei) / Number(tokenPriceBigInt);
        } else if (type === "SELL") {
            const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it, handle as Number to ensure float result
            outputAmount = (amountInWei / 1e18) * (Number(tokenPriceBigInt) / 1e18);
        }

        setEstimateAmount((outputAmount.toFixed(9)).toString());
    }

    const funcInput = async(e: any) => {
        setInput(e);
        await showOutput(e.target.value);    
    }   

    useEffect(() => {
        const getMaxAmount = async() => {
            if (type == "BUY") setMaxAmount(nativeBalanceString);
            else if(type == "SELL" && detailData) {
                const rpcUrl = getRpcUrl(detailData);
                const tokenProvider = new ethers.JsonRpcProvider(rpcUrl);
                const tokenContract = new ethers.Contract(detailData.tokenAddress, tokenABI, tokenProvider);
                const realAddress = getContractAddress(detailData.network);
                const tokenBalance = await tokenContract.balanceOf(realAddress); 
                setMaxAmount(ethers.formatEther(tokenBalance));
            }
        }
        showOutput(amount);
        if (detailData) {
            getMaxAmount();
        }
    }, [type]);

    const handleClose = () => {
        setOpen(false);
    };
    const handlePost = async() => {
        if(id === "" || address === undefined || comment === "" ||  chain === "") return;
        const body = {
            tokenAddress: id, 
            replyAddress: address, 
            comment: comment, 
            network: chain
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/chats/reply`, body)
                .then(function (response) {
                    // console.log("response", response)
                    setChatData(response.data);
                    // setTradeData(response.data)
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
        setOpen(false);
    };    
    useEffect(() => {
        const init = async() => {
            if (!walletProvider) {
                // console.log("Wallet provider is not available.");
                return;
            }
            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const tempSigner = await ethersProvider.getSigner();
            setSigner(tempSigner);
            const realAddress = getContractAddress("BychainId", chainId);
            const realABI = getContractABI("BychainId", chainId);
            const tempFairLaunchContract = new ethers.Contract(realAddress, realABI, tempSigner);
            setFairLaunchContract(tempFairLaunchContract);
            const nativeBalance = await tempSigner.provider.getBalance(tempSigner.address);
            const tempNativeBalanceString = ethers.formatEther(nativeBalance);
            setNativeBalanceString(tempNativeBalanceString);
        }
        init();
    }, [chainId, address])

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const funcSetLPBalance = async (response: any) => {
        if (!response) return;
        const rpcUrl = getRpcUrl(response);
        const tokenProvider = new ethers.JsonRpcProvider(rpcUrl);
        const tokenContract = new ethers.Contract(response.tokenAddress, tokenABI, tokenProvider);
        const realAddress = getContractAddress(response.network);
        const LPBalance = await tokenContract.balanceOf(realAddress); 
        setLPBalance( parseFloat(ethers.formatEther( LPBalance))  / totalSupply);
    }

    const funcGetLaunched = async (tempDetailData: any) => {
        if (!tempDetailData) return;
        const rpcUrl = getRpcUrl(tempDetailData);
        const tokenProvider = new ethers.JsonRpcProvider(rpcUrl);
        const tokenContract = new ethers.Contract(tempDetailData.tokenAddress, tokenABI, tokenProvider);
        setLaunched(await tokenContract.launched());  // check token launched variable
    }

    const getDatils = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/tokens/${chain}/${id}`)
                .then(function (response) {
                    setDetailData(response.data.tokenDetils)
                    setChatData(response.data.chatList);
                    setHolders(response.data.holdersDetails)
                    setChartData(response.data.chartList);
                    funcSetLPBalance(response.data.tokenDetils);
                    funcGetLaunched(response.data.tokenDetils);
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
    }

    const getTrades = async () => {
        const body = {
            tokenAddress: id,
            network: chain
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/trades`, body)
                .then(function (response) {
                    setTradeData(response.data)
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
    }

    const dateFormat = (date: string) => {
        const dateObj = new Date(date);

        // Format the date components
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = dateObj.getDate().toString().padStart(2, '0');
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate
    }

    const swapHandler = async () => {
        if (!detailData) return;
        let ethAmount, tokenAmount;
        let inputAmount = amount.toString();
        if (!inputAmount) inputAmount = "0";
        let tempChainId = 11155111;
        switch (chain) {
            case "Ethereum":
                tempChainId = 1;
                break;
            case "Avax":
                tempChainId = 43114
                break;        
            case "Arbitrum":
                tempChainId = 42161
                break;
            case "Base":
                tempChainId = 8453
                break;   
            case "Sepolia":
                tempChainId = 11155111
                break;             
            default:
                break;
        }
        await switchNetwork(tempChainId);

        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const tempSigner = await ethersProvider.getSigner();
        const realAddress = getContractAddress(chain);
        const realABI = getContractABI(chain);
        const tempFairLaunchContract = new ethers.Contract(realAddress, realABI, tempSigner);

        const tokenPriceBigInt = BigInt(await tempFairLaunchContract.getPrice(detailData?.tokenAddress));
        const realInputAmount = parseFloat(inputAmount) * 97 / 100; // 3% Fee of Buy & Sell

        // let outputAmount = 0; // This will now be a float
        let txHash: any;
        if (type === "BUY") {
            const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it
            ethAmount =  amount;
            tokenAmount = (Number(amountInWei) / Number(tokenPriceBigInt)).toFixed(9).toString();
            const transactionResponse = await tempFairLaunchContract.buyTokens(detailData?.tokenAddress, {value: ethers.parseEther(amount.toString())});
            const waitForResponse = await transactionResponse.wait();
            txHash = waitForResponse.hash;
        } else if (type === "SELL") {
            const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it, handle as Number to ensure float result
            tokenAmount = amount;
            ethAmount = ((amountInWei / 1e18) * (Number(tokenPriceBigInt) / 1e18)).toFixed(9).toString();
            const transactionResponse = await tempFairLaunchContract.sellTokens(detailData?.tokenAddress, amount.toString());
            const waitForResponse = await transactionResponse.wait();
            txHash = waitForResponse.hash;
        }

        const rpcUrl = getRpcUrl(detailData);
        const tokenProvider = new ethers.JsonRpcProvider(rpcUrl);
        const tokenContract = new ethers.Contract(detailData?.tokenAddress, tokenABI, tokenProvider);
        const tokenBalanceOfUser = await tokenContract.balanceOf(tempSigner?.address); // Make sure this is the correct address you want to check

        const ethPriceBigInt = BigInt(await tempFairLaunchContract.getETHPriceByUSD());
        const tokenPriceETH = Number(tokenPriceBigInt) / Math.pow(10, 18);
        const ethPriceUSD = Number(ethPriceBigInt) / Math.pow(10, 6); 
        const tokenPriceUSD = tokenPriceETH * ethPriceUSD;
        const body = {
            type: "swap",
            data: {
                tokenName: detailData?.tokenName,
                tokenSymbol:  detailData?.tokenSymbol,
                tokenAddress: detailData?.tokenAddress,
                tokenImage: detailData?.tokenImage,
                creatorAddress: detailData?.creatorAddress,
                swapperAddress: address, // wallet address
                type: type,
                ethAmount: ethAmount,
                tokenAmount: tokenAmount,
                network: chain,
                txHash: txHash,
                tokenPrice: tokenPriceUSD.toString(),
                tokenBalanceOfUser: ethers.formatEther(tokenBalanceOfUser)
            }
        }

        socket.emit("message", JSON.stringify(body))
    }

    React.useEffect(() => {
        getDatils();
        getTrades();
    }, []);


    React.useEffect(() => {
        if(chartData.length <= 0) return;
        let root = am5.Root.new("chartdiv");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0
        }));


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineX.set("forceHidden", true);
        cursor.lineY.set("forceHidden", true);

        // Generate random data
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        let value = 20;
        function generateData() {
            value = am5.math.round(Math.random() * 10 - 4.8 + value, 1);
            if (value < 0) {
                value = Math.random() * 10;
            }

            if (value > 100) {
                value = 100 - Math.random() * 10;
            }
            am5.time.add(date, "day", 1);
            return {
                date: date.getTime(),
                value: value
            };
        }

        // function generateDatas(count: number) {
        //     let data = [];
        //     for (var i = 0; i < count; ++i) {
        //         data.push(generateData());
        //     }
        //     return data;
        // }


        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {
                timeUnit: "second",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
                minGridDistance: 90
            })
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));

        series.fills.template.setAll({
            fillOpacity: 0.2,
            visible: true
        });

        root.interfaceColors.set("grid", am5.color(0xffffff));

        // Set data
        // let data = generateDatas(300);
        let data = chartData.map(item => {
            return {
                value: item.tokenPrice, 
                date: item.date * 1000
            };
        });
        // chartData
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);
        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} sm={8} mx='auto'>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize:24, fontWeight:'bold'}}>{detailData?.price.toFixed(9)}</Typography>
                            <Typography sx={{fontSize:18, fontWeight:'bold'}}>
                                <a href={getHref(detailData?.network, detailData?.tokenAddress)} target="_blank" style={{textDecoration: 'none'}}><Typography sx={{ color: '#dcc814' }}>{(detailData?.tokenAddress)?.slice(0, 5)}...{(detailData?.tokenAddress)?.slice(-4)}</Typography></a>
                            </Typography>
                            <Typography sx={{fontSize:18, fontWeight:'bold', color: 'green'}}>{launched == true ? "Launched Token" : ""}</Typography>
                            <div id="chartdiv" style={{ width: '100%', height: '50vh' }}></div>
                        </CardContent>
                    </Card>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTab} aria-label="lab API tabs example">
                                    <Tab label="Chat" value="1" />
                                    <Tab label="Trade" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12}>
                                        {chatData.map((item: any, index: number) => (
                                            <Card sx={{ mb: '0.5rem' }}>
                                                <CardContent>
                                                    <Box display='flex' alignItems='center'>
                                                        <Avatar src={AvaxIcon} sx={{ width: 20, height: 20, mr: '0.5rem' }} alt="" />
                                                        <a href={getHref(item.network, item.replyAddress)} target="_blank" style={{textDecoration: 'none'}}><Typography sx={{ color: '#dcc814' }}>{(item.replyAddress).slice(0, 5)}...{(item.replyAddress).slice(-4)}</Typography></a>
                                                        <Typography sx={{ ml: '0.5rem', color: '#94a3b8' }}>{dateFormat(item.date)}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography>{item.comment}</Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Grid2>
                                    <Grid2 xs={12} mx='auto' textAlign='center'>
                                        <Button variant="outlined" onClick={handleClickOpen}>Post Reply</Button>
                                    </Grid2>
                                </Grid2>
                            </TabPanel>
                            <TabPanel value="2">
                                <CustomPaginationActionsTable data={tradeData} />
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid2>
                <Grid2 xs={12} sm={4} mx='auto'>
                    <Card sx={{ border: '1px solid #FFA800', mb: '1rem' }}>
                        <CardContent>
                            <Grid2 container spacing={2}>
                                <Grid2 xs={6}><Button variant={type === "BUY" ? "contained" : "outlined"} color="warning" sx={{  fontSize: 24, fontWeight: 'bold'}} onClick={() => setType("BUY")} fullWidth>BUY</Button></Grid2>
                                <Grid2 xs={6}><Button variant={type === "SELL" ? "contained" : "outlined"} color="success" sx={{  fontSize: 24, fontWeight: 'bold'}} onClick={() => setType("SELL")} fullWidth>Sell</Button></Grid2>
                                <Grid2 xs={12} textAlign='end'><Typography>Max: {maxAmount}</Typography></Grid2>
                                <Grid2 xs={12} mb='1rem'>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            value={amount}
                                            onChange={(e) => funcInput(e)}
                                            type="number"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                    >
                                                        { type === "BUY" ? chain?.toLowerCase() == "base" ? 
                                                        <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} /> : chain?.toLowerCase() == "arbitrum" ?
                                                        <Avatar src={ArbitrumIcon} sx={{ width: 25, height: 25 }} /> : chain?.toLowerCase() == "avax" ?
                                                        <Avatar src={AvaxIcon} sx={{ width: 25, height: 25 }} /> : "" :  
                                                        <Box sx={{ width: { sm: 25, xs: 25 }, height: { sm: 25, xs: 25 }, margin: 'auto', }}>
                                                            <img src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${detailData?.tokenImage}`} width="100%" alt="image" />
                                                        </Box>
                                                        }

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid2>
                                <Grid2 xs={12} textAlign='start'><Typography>{estimateAmount}</Typography></Grid2>
                                <Grid2 xs={12}><Button variant="contained" disabled={launched} onClick={swapHandler} sx={{ background: '#FFA800', fontSize: 24, fontWeight: 'bold' }} fullWidth>Place Trade</Button></Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                    <MainCard image={detailData?.tokenImage} title={detailData?.tokenName} ticker={detailData?.tokenSymbol} market_cap={detailData?.marketcap} trades={detailData?.replies} deployer={detailData?.creatorAddress} web={detailData?.webLink} telegram={detailData?.telegramLink} twitter={detailData?.twitterLink} />
                    {(
                        <Card sx={{ border: '1px solid #FFA800', mb: '1rem' }}>
                            <CardContent>
                                <List>
                                    <ListItem disablePadding dense={true} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <>
                                            <Typography>1. <a target="_blank" href={getHref(chain, getContractAddress(chain))} style={{ textDecoration: 'none', color: 'white' }}>{(getContractAddress(chain)).slice(0, 5)}...{(getContractAddress(chain)).slice(-4)}(Bonding Curve)</a></Typography>
                                            <Typography>{lpBalance * 100} %</Typography>
                                        </>
                                    </ListItem>
                                    {holders.length > 0 && holders.map((item: any, index: number) => (
                                         <ListItem disablePadding dense={true} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography>{index + 2}. <a target="_blank" href={getHref(chain, item.holderAddress)} style={{ textDecoration: 'none', color: 'white' }}>{(item.holderAddress).slice(0, 5)}...{(item.holderAddress).slice(-4)}{item.holderAddress==item.creatorAddress ? " (Creator) " : ""}</a></Typography>
                                            <Typography>{item.tokenAmount / totalSupply * 100} %</Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    )}
                </Grid2>
            </Grid2>
            <Dialog
                open={open}                
                onClose={handleClose}
                maxWidth={`md`}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add a comment"}
                </DialogTitle>
                <DialogContent>
                    <TextField multiline rows={4} sx={{width: {xs: '100%', sm: 500}}} value={comment} onChange={(e) => setComment(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" sx={{ border: '1px solid #FF0000', background: '#FF000020', color: 'white' }} onClick={handleClose}>Cancel</Button>
                    <Button variant="outlined" sx={{ border: '1px solid #FFA800', background: '#FFA80020', color: 'white' }} onClick={handlePost}>Post</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}