import React, { Dispatch, SetStateAction, createContext, useContext } from "react";
import { socket } from "../utils/socket";

interface MainContextProps {
    mobileOpen: boolean;
    isClosing: boolean;
    chainNetwork: string;
    setChainNetwork: Dispatch<SetStateAction<string>>;
    setMobileOpen: Dispatch<SetStateAction<boolean>>;
    setIsClosing: Dispatch<SetStateAction<boolean>>;
    handleDrawerClose : () => void;
    handleDrawerTransitionEnd  : () => void;
    handleDrawerToggle  : () => void;
}

const MainContext = createContext<MainContextProps | undefined>(undefined);

export const MainContextProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [chainNetwork, setChainNetwork] = React.useState('All')

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
      };
    
      const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
      };
    
      const handleDrawerToggle = () => {
        if (!isClosing) {
          setMobileOpen(!mobileOpen);
        }
      };

      React.useEffect(() => {
        socket.on("message", (response: any) => {
          // console.log("message", response);
        });

        return () => {
          socket.off("message");
        }
      }, []);

    const contextValue: MainContextProps = {
        mobileOpen,
        isClosing,
        chainNetwork,
        setChainNetwork,
        setMobileOpen,
        setIsClosing,
        handleDrawerClose,
        handleDrawerTransitionEnd,
        handleDrawerToggle
    }

    return <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
}

export const useMainContext = () => {
    const context = useContext(MainContext);

    if (!context) {
        throw new Error('MainContext must be used within a MainContextProvider');
    }
    return context;
}