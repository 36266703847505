import { io } from "socket.io-client";

const SERVER_URL = `${process.env.REACT_APP_API_URL}`;


const socketOptions: any = {
    autoConnect: true,
    reconnectionDelayMax: 1000,
};

export const socket = io(SERVER_URL, socketOptions);