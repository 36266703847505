import Header from "./header";
import Sidebar from "./sidebar";
import { Outlet } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import { Box, CssBaseline } from "@mui/material";

const drawerWidth = 240;

function MainLayout() {

    return (
        <div className="MainLayout">
            <Box sx={{ display: 'flex' }}>
                <Header drawerWidth={drawerWidth} />
                <CssBaseline />
                <Sidebar />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop:{sm: '0', xs: '2.5rem'}  }}
                    
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </div>
    );
}

export default MainLayout;
