import { Card, CardContent, Container, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MainCard from "../components/cards";
import React from "react";
import axios from "axios";
import { BrowserProvider, Eip1193Provider } from "ethers";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";

function MyCurvsPage() {
    const { walletProvider } = useWeb3ModalProvider();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [curvs, setCurvs] = React.useState<any | []>([]);
    const [network, setNetwork] = React.useState('All');
    const getMyTokens = async () => {
        if (!walletProvider) {
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const signer = await ethersProvider.getSigner()
        const body = {
            network: network,
            userAddress: signer.address
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/tokens/myPage`, body)
                .then(function (response) {
                    setCurvs(response.data)
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
    }

    React.useEffect(() => {
        getMyTokens();
    }, [address, chainId, network]);
    return (
        <div className="DashboardPage">
            <Container>
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={3} mx="auto" mb='1rem'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Network</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={network}
                                label="Curv Lvl"
                                onChange={(e) => setNetwork(e.target.value)}
                            >
                                <MenuItem value='All'>All</MenuItem>
                                <MenuItem value='Ethereum'>Ethereum</MenuItem>
                                <MenuItem value='Base'>Base</MenuItem>
                                <MenuItem value='Avax'>Avax</MenuItem>
                                <MenuItem value='Arbitrum'>Arbitrum</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2>
                </Grid2>
                {curvs.length > 0 ? (
                    <Grid2 container spacing={2}>
                        {
                            curvs.map((item: any, index: number) => (
                                <Grid2 xs={12} sm={6} mx='auto'>
                                    <Link to={`/${item?.network}/${item.tokenAddress}`} style={{ textDecoration: 'none' }}>
                                        <MainCard network={item.network} image={item.tokenImage} title={item.tokenName} ticker={item.tokenSymbol} market_cap={item.marketcap} trades={item.replies} deployer={item.creatorAddress} web={item.webLink} telegram={item.telegramLink} twitter={item.twitterLink} />
                                    </Link>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                ) : (
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} mx='auto'>
                            <Card>
                                <CardContent>
                                    <Typography textAlign='center'>Empty Curv Data</Typography>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </Grid2>
                )}
            </Container>
        </div>
    );
}

export default MyCurvsPage;
