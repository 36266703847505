import { Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Pagination, PaginationItem, Select, SelectChangeEvent, Slide, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import DelegateBanner from '../assets/images/delegate.png';
import SwapBanner from '../assets/images/swap.png';
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MainCard from "../components/cards";
import React, { useEffect } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";

const data = [
    { image: 'https://pump.mypinata.cloud/ipfs/QmUHwxn4aXe9gRiqndxftZQJte7wG3sz6ucJ6bNpbUdRjw', title: 'CAT', ticker: 'CAT', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmdDpp9BZB6ANrdJ9TviP4cZB6EJUu1dJLNV55tsLHPbF3' },
    { image: 'https://pump.mypinata.cloud/ipfs/Qmb3Yg2sJtb8t2JE8DYzPwC9mSwKBaieWYLPzoCZoZpuQT', title: 'HUHCAT', ticker: 'HUH', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmdDpp9BZB6ANrdJ9TviP4cZB6EJUu1dJLNV55tsLHPbF3' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmQW8jPbCG6bWany5A9P4Ls359oRWBLkLDgLKmGrhKmR3j', title: 'Shark Dog Jeff', ticker: 'SharkDJ', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmdDpp9BZB6ANrdJ9TviP4cZB6EJUu1dJLNV55tsLHPbF3' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTgsNgeWQmxidZDKVEGE2nNYXbNr59DbQAje9xLxhH3YS', title: 'Tigerwifhat', ticker: 'Tigerwifhat', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmdDpp9BZB6ANrdJ9TviP4cZB6EJUu1dJLNV55tsLHPbF3' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmcYFGouaHshtbqzwH5b8e1EE8X86QGQMkoBbJw8nCrZHy', title: 'SAUSAGE DOG', ticker: 'DOGWIFDOG', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmdDpp9BZB6ANrdJ9TviP4cZB6EJUu1dJLNV55tsLHPbF3' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTjCSZZUWtN7ufaWUf61rau1vGAyF31KDqzT8MUHYx2bW', title: '10X Challenge', ticker: 'Avada Kedavara!', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmTpKfKCxAswoowXkiBTyLF6HoeYVu5quVK13FRTRQvq6i' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTRE3snxnZkvpHdsjETs4jjFjWY7huRWx27mskVCiaCiy', title: 'McCat', ticker: 'MCCAT', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmTpKfKCxAswoowXkiBTyLF6HoeYVu5quVK13FRTRQvq6i' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTmNsuwZgYhsACMjtWsU8BQa2WZRhvTkJWNhdpJQPGpKo', title: 'Rooger Vereet', ticker: 'RoogerVer', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmTpKfKCxAswoowXkiBTyLF6HoeYVu5quVK13FRTRQvq6i' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmUN2qaPdTe4LKXhvpyoVpSx6pwmcnf6G4ToFvmQSTt2G1', title: 'Sadcat', ticker: 'Sadcat', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmTpKfKCxAswoowXkiBTyLF6HoeYVu5quVK13FRTRQvq6i' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmdNuseHFi1g2vnDA8U51kUHD1ST2QEutN1zmpir8BPy6P', title: 'DOGESWAP', ticker: 'DOGESWAP', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmTpKfKCxAswoowXkiBTyLF6HoeYVu5quVK13FRTRQvq6i' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmU2q9JNUW5tvzq4J4uNcZ1AHAQAuRqgDfYBzdwHhvWM2b', title: 'BearyPotter', ticker: 'BEARY', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmYDgAgqm2LJJYWermTarRHJ86yLLvGAfh4HnhNbYvjfMS', title: 'Llama Beats Bucks', ticker: 'LBB', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTsmLJNpcoYTM8Mu6A8NYzRCzusa1dascQ9aRiZ4BKmS7', title: 'Elon Milady', ticker: 'ELADYS', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmQ93fQQaz4tRjrbW6y5FwtHWQrMRVq1W2fqD8VQvwRi8k', title: 'Werld Wer Twee', ticker: 'WW3', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmT7fHuYM35EtR4h4TqmWRL3fhgKp1ZASDpnSoBKC187Zn', title: 'CATBOOK', ticker: 'CATBOOK', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmVf6rtdZcs7hPH2hA993iN7ZANBgLp9zqa6yZN4kJiWRd', title: 'DJ Windows 95', ticker: '$DJW95', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmcK4EiVKfujKtTtZr1VJcMKdq61bD8QGjHy9ZDUQJ2b88', title: 'Scimmer ', ticker: 'Scimmer', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmcaV1KibrETTuwjRtH6hpKPGGQh7gEpJGqesfrnxg4vrx', title: 'Dordolec ', ticker: '$DDLC', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
    { image: 'https://pump.mypinata.cloud/ipfs/QmTwhvyKAuEkgbiYYtXgftDP7G43ximNbzPYE7BSzzm1S7', title: 'URSS ', ticker: 'URSS ', market_cap: 23.3, trades: 20, deployer: 'https://pump.mypinata.cloud/ipfs/QmbSbf99otz7WSDHsji7q1SckNtRY5wSMxryaS1tqdJtwU' },
]

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardPage() {
    let checkedStatus = localStorage.getItem('checked');
    const [curvsList, setCurvsList] = React.useState([]);
    const [open, setOpen] = React.useState(checkedStatus !== null && JSON.parse(checkedStatus) === true ? false : true);
    const [orderType, setOrderType] = React.useState('creationTime');
    const [orderFlag, setOrderFlag] = React.useState('DESC');
    const [searchWord, setSearchWord] = React.useState('');
    const [network, setNetwork] = React.useState('All');
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(10);
    const [recommendToken, setRecommendToken] = React.useState<any>(null);

    const getMyTokens = async () => {
        const body = {
            orderType: orderType,
            orderFlag: orderFlag,
            searchWord: searchWord,
            network: network,
            pageNumber: pageNumber
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/tokens`, body)
                .then(function (response) {
                    setCurvsList(response.data.tokenList);
                    setTotalPage(response.data.totalPages);
                    setRecommendToken(response.data.recommendToken);
                })
                .catch(function (error) {
                    // console.log(error);
                });
        } catch (error) {

        }
    }

    const handleChange = (event: any, value: any) => {
        setPageNumber(value);
        // This logs the current page number to the console
    };

    React.useEffect(() => {
        getMyTokens();
    }, []);

    React.useEffect(() => {
        getMyTokens();
    }, [pageNumber, orderType, orderFlag, searchWord, network]);

    const handleClose = () => {
        localStorage.setItem('checked', JSON.stringify(true));
        setOpen(false);
    };

    return (
        <div className="DashboardPage">
            <Container>
                {recommendToken && (
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} mx='auto'>
                            <Link to={`/${recommendToken?.network}/${recommendToken.tokenAddress}`} style={{ textDecoration: 'none' }}>
                                <MainCard network={recommendToken.network} image={recommendToken.tokenImage} title={recommendToken.tokenName} ticker={recommendToken.tokenSymbol} market_cap={recommendToken.marketcap} trades={recommendToken.replies} deployer={recommendToken.creatorAddress} web={recommendToken.webLink} telegram={recommendToken.telegramLink} twitter={recommendToken.twitterLink} />
                            </Link>
                        </Grid2>
                    </Grid2>
                )}
                <Grid2 container spacing={2} mb="1rem">
                    <Grid2 xs={6} sm={3} mb="1rem">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Latest Curvs</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={orderType}
                                label="Latest Curvs"
                                onChange={(e) => setOrderType(e.target.value)}
                            >
                                <MenuItem value="marketcap">Marketcap</MenuItem>
                                <MenuItem value="creationTime">CreationTime</MenuItem>
                                <MenuItem value="updateTime">UpdateTime</MenuItem>
                                <MenuItem value="replies">Replies</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={6} sm={3} mb="1rem">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Network</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={network}
                                label="Curv Lvl"
                                onChange={(e) => setNetwork(e.target.value)}
                            >
                                <MenuItem value='All'>All Networks</MenuItem>
                                <MenuItem value='Ethereum'>Ethereum</MenuItem>
                                <MenuItem value='Base'>Base</MenuItem>
                                <MenuItem value='Avax'>Avax</MenuItem>
                                <MenuItem value='Arbitrum'>Arbitrum</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={6} sm={3} mb="1rem">
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Search</InputLabel> */}
                            <TextField label="Search Keyword" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={6} sm={3} mb="1rem">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Curv Lvl</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={orderFlag}
                                label="Network"
                                onChange={(e) => setOrderFlag(e.target.value)}
                            >
                                <MenuItem value='ASC'>ASC</MenuItem>
                                <MenuItem value='DESC'>DESC</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2>
                </Grid2>
                {curvsList.length > 0 ? (
                    <Grid2 container spacing={2}>
                        {
                            curvsList.map((item: any, index: number) => (
                                <Grid2 xs={12} sm={6}>
                                    <Link to={`/${item?.network}/${item.tokenAddress}`} style={{ textDecoration: 'none' }}>
                                        <MainCard network={item.network} image={item.tokenImage} title={item.tokenName} ticker={item.tokenSymbol} market_cap={item.marketcap} trades={item.replies} deployer={item.creatorAddress} web={item.webLink} telegram={item.telegramLink} twitter={item.twitterLink} />
                                    </Link>
                                </Grid2>
                            ))
                        }
                    </Grid2>

                ) : (
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} mx='auto' mb="1rem">
                            <Card>
                                <CardContent>
                                    <Typography textAlign='center'>Empty Curv Data</Typography>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </Grid2>
                )}
            </Container>
            {recommendToken && (
                <Grid2 sx={{ display: 'flex' }}>
                    <Stack spacing={2} mx='auto'>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={totalPage}
                            page={pageNumber}  // current page
                            onChange={handleChange}  // handle page change
                            renderItem={(data) => (
                                <PaginationItem
                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...data}
                                />
                            )}
                        />
                    </Stack>
                </Grid2>
            )}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"How It Works"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" mb='1rem'>
                        This platform prevents rugs by making sure that all created tokens are safe. Each token on this platform is a fair-launch with no presale and full transparency on allocation.
                    </DialogContentText>
                    <Typography>step 1: Choose the token you like</Typography>
                    <Typography>step 2: Buy the token on the selected chain</Typography>
                    <Typography>step 3: Sell at any time to lock in your profits or losses</Typography>
                    <Typography>step 4: When enough people buy, it reaches a market cap of $69k</Typography>
                    <Typography>step 5: $12k of liquidity is then deposited on UNISWAP and burned</Typography>
                </DialogContent>
                <DialogActions>
                    <Container>
                        <Button onClick={handleClose} variant="outlined" sx={{ border: '1px solid #FFA800', background: '#FFA80020', color: 'white', mb: '1rem' }} fullWidth >Let's go!</Button>
                    </Container>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DashboardPage;
