import { Link } from "react-router-dom";
import { MainRouter } from "../../routers";
import Logo from '../../assets/images/logo.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useMainContext } from "../../context/mainContext";
import { Avatar, Box, Divider, Drawer, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AvaxIcon from '../../assets/images/coin/avax.png';
import HexIcon from '../../assets/images/hex.svg';
import TelegramIcon from '../../assets/images/telegram.svg';
import TelegramSupportIcon from "../../assets/images/telegramSupport.svg";
import EmailIcon from "../../assets/images/email.svg";
import TwitterIcon from '../../assets/images/x.svg';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

function Sidebar(props: Props) {
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const { mobileOpen, handleDrawerClose, handleDrawerTransitionEnd, handleDrawerToggle } = useMainContext();
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  useEffect(() => {

  }, []);

  const draw = (
    <Box>
      <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent:'center', paddingTop: '2rem', paddingBottom: '0.5rem' }}>
        <Link to="/">
          <img src={Logo} height={80} style={{ margin: "auto" }} alt="logo" />
        </Link>        
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: "0.5rem", marginBottom: '0.5rem', display: { sm: 'none' } }}
        >
          <MenuOpenIcon />
        </IconButton>
      </Toolbar>
      <Divider style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} />
      <Divider style={{ borderColor: 'transparent' }} />
      <List>
        {MainRouter.main.map((router, index) => (
          <Link key={index} to={router.path} style={{ textDecoration: 'none', color: '#ffffff', fontFamily: "JosefinSans" }}>
            <ListItem  >
              <ListItemButton sx={{ borderRadius: 50 }}>
                <img src={router.icon} height={35} width={35} alt={router.title} />
                <ListItemText primary={router.title} sx={{ ml: '0.5rem', fontFamily: "JosefinSans" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider style={{ borderColor: 'transparent' }} />
      <Divider style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} />
      <Box sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '3rem' }}>
        <Typography sx={{color:'#FF0089', fontSize:16, mb:'0.5rem'}}>How it works</Typography>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Link to="https://www.youtube.com/@Curvdotme" target="_blank"><img src={HexIcon} width={30} height={30} alt="youtube"/></Link>
          <Link to="https://t.me/curvcommunity" target="_blank"><img src={TelegramIcon} width={30} height={30} alt="telegramCommunity"/></Link>
          <Link to="https://twitter.com/Curvdotme" target="_blank"><img src={TwitterIcon} width={20} height={20} alt="twitter"/></Link>
          <Link to="https://t.me/curvsupport" target="_blank"><img src={TelegramSupportIcon} width={25} height={25} alt="telegramSupport"/></Link>
          <Link to="mailto://team@curv.me" target="_blank"><img src={EmailIcon} width={30} height={30} alt="mail"/></Link>
        </Box>
      </Box>
    </Box>
  );


  return (
    <div className="Sidebar" style={{ background: '#1C2132' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          container={container}
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#1C2132', backgroundImage: 'none', border: 'none' },
          }}
        >
          {draw}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none', backgroundColor: '#1C2132' },
          }}
          style={{ background: 'transparent' }}
          open
        >
          {draw}
        </Drawer>
      </Box>
    </div>
  );
}

export default Sidebar;
