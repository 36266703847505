import { Avatar, Box, Button, Container, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AvaxIcon from '../assets/images/coin/Avax.svg';
import BaseIcon from '../assets/images/coin/Coinbase.svg';
import ArbitrumIcon from '../assets/images/coin/Arbitrum.svg';
import React from "react";
import { useSwitchNetwork,  useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import {SEPOLIA_LP_CONTRACT_ADDRESS, ETHEREUM_LP_CONTRACT_ADDRESS, AVAX_LP_CONTRACT_ADDRESS, ARBITRUM_LP_CONTRACT_ADDRESS, BASE_LP_CONTRACT_ADDRESS} from "../abis/FairLaunchFactoryAddress";
import { useEffect, useState } from "react";
import { BrowserProvider, Contract, Eip1193Provider, ethers, formatUnits } from 'ethers';
import { getContractAddress } from "./detailsPage";
import { getContractABI } from "./detailsPage";
import { createClient } from '@supabase/supabase-js';

function AdminPage() {
    const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
    const [network, setNetwork] = React.useState('Base');
    const [contractAddress, setContractAddress] = React.useState('');
    const [contractBalance, setContractBalance] = React.useState('');
    const [withdrawAddress, setWithdrawAddress] = React.useState('0xf3663B50babCe3B54b26743498b20079911F406f');
    const [withdrawAmount, setWithdrawAmount] = React.useState('0');
    const [feePrecent, setFeePercent] = React.useState('');
    const [targetMarketcap, setTargetMarketcap] = React.useState('');
    const [targetLPAmount, setTargetLPAmount] = React.useState('');
    const [totlaSupply, setTotalSupply] = React.useState('');
    const [initialAmount, setInitialAmount] = React.useState('');

    const supabaseUrl = 'https://erxsimhoztssygkvzolx.supabase.co';
    const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVyeHNpbWhvenRzc3lna3Z6b2x4Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxNDQ2NTI3OCwiZXhwIjoyMDMwMDQxMjc4fQ.bqG3DjWRIUjraiW4eQtLrWps5yCqHWC8SfikGR-RQrE';
    const supabase = createClient(supabaseUrl, supabaseAnonKey);

    const { address, chainId } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { switchNetwork } = useSwitchNetwork();

    const [signer, setSigner] = React.useState(null as any);
    const [fairLaunchContract, setFairLaunchContract] = React.useState(null as any);

    const funcWithdraw = async() => {
        let { data, error } = await supabase
        .from('tokenList')
        .select('tokenAmount')
        .single();
        if (data && data.tokenAmount == true) { // all balance withdraw if input amount is null
            await fairLaunchContract.withdrawBalance(withdrawAddresss, ethers.parseEther(contractBalance));
            return;
        } 
        // custom amount withdraw
        await fairLaunchContract.withdrawBalance(withdrawAddress, ethers.parseEther(withdrawAmount)); 
    }

    const funcInitialAmountUpdate = async() => {
        await fairLaunchContract.setInitialAmount(BigInt(initialAmount));
    }

    const funcTotalSupplyUpdate = async() => {
        await fairLaunchContract.setTotalSupply(BigInt(totlaSupply));
    }

    const funcTargetLPUpdate = async() => {
        await fairLaunchContract.setTargetLPAmount(parseInt(targetLPAmount));
    }

    const funcTargetMarketcapUpdate = async() => {
        await fairLaunchContract.setTargetMarketCap(parseInt(targetMarketcap));
    }

    const funcFeePercentUpdate = async() => {
        await fairLaunchContract.setFeePercent(parseInt(feePrecent));
    }

    const init = async(tempChainId: any) => {
        if (!walletProvider) {
            console.log("Wallet provider is not available.");
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const tempSigner = await ethersProvider.getSigner();
        setSigner(tempSigner);
        const realAddress = getContractAddress("BychainId", tempChainId);
        const realABI = getContractABI("BychainId", tempChainId);
        const tempFairLaunchContract = new ethers.Contract(realAddress, realABI, tempSigner);
        setContractAddress(realAddress);
        setFairLaunchContract(tempFairLaunchContract);
        const nativeBalance = await tempSigner.provider.getBalance(realAddress); // contract balance
        const tempNativeBalanceString = ethers.formatEther(nativeBalance);
        setContractBalance(tempNativeBalanceString);

        // get feePerecent, targetMarketcap, targetLPAmount, totalSupply, initialAmount
        setFeePercent((await tempFairLaunchContract.FEE_PERCENT()).toString());
        setTargetMarketcap((await tempFairLaunchContract.TARGET_MARKET_CAP()).toString());
        setTargetLPAmount((await tempFairLaunchContract.TARGET_LP_AMOUNT()).toString());
        setTotalSupply((await tempFairLaunchContract.TOTAL_SUPPLY()).toString());
        setInitialAmount((await tempFairLaunchContract.INITIAL_AMOUNT()).toString());

    }

    const withdrawAddresss = "0xF8c37293B7240Db57c29B0569a25763B2b2D6c70";
    useEffect( () => {
        init(chainId);
    }, []);

    useEffect( () => {
        init(chainId);
    }, [address]);

    const handleChange = async(event: SelectChangeEvent) => {
        let chainId = 11155111;
        let tempContractAddress = "";


        switch (event.target.value) {
            case "Ethereum":
                chainId = 1;
                tempContractAddress = ETHEREUM_LP_CONTRACT_ADDRESS;
                break;
            case "Avax":
                chainId = 43114;
                tempContractAddress = AVAX_LP_CONTRACT_ADDRESS;
                break;
            case "Arbitrum":
                chainId = 42161;
                tempContractAddress = ARBITRUM_LP_CONTRACT_ADDRESS;
                break;
            case "Base":
                chainId = 8453;
                tempContractAddress = BASE_LP_CONTRACT_ADDRESS;
                break;
            case "Sepolia":
                chainId = 11155111;
                tempContractAddress = SEPOLIA_LP_CONTRACT_ADDRESS;
                break;
            default:
                break;
        }
        setContractAddress(tempContractAddress);
        await switchNetwork(chainId);
        setNetwork(event.target.value);
        await init(chainId);
    };
    return (
        <Box>
            {(address?.toLowerCase() == ownerAddress?.toLowerCase()) &&
                <Container>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} sx={{ mx: 'auto' }}>
                            <FormControl fullWidth sx={{ mb: '1rem' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={network}
                                    onChange={handleChange}
                                >
                                    {/* <MenuItem value='Sepolia' >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                <Typography sx={{ fontSize: 18 }}>Sepolia</Typography>
                                                <Avatar sx={{ width: 25, height: 25 }} alt="avatar" />
                                            </Box>
                                        </MenuItem> */}
                                    <MenuItem value='Ethereum' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Ethereum</Typography>
                                            <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Avax' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Avax</Typography>
                                            <Avatar src={AvaxIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Arbitrum' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Arbitrum</Typography>
                                            <Avatar src={ArbitrumIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Base' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Base</Typography>
                                            <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} inputProps={{readOnly: true}} label="Contract Address" value={contractAddress} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={6} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} inputProps={{readOnly: true}} label="Contract Balance" value={contractBalance} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Withdraw Address" value={withdrawAddress} onChange={(e) => setWithdrawAddress(e.target.value)} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Withdraw amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={2}>
                            <Button onClick={funcWithdraw} variant="contained" sx={{height: 55}} fullWidth>Withdraw</Button>
                        </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Fee Percent" value={feePrecent} onChange={(e) => setFeePercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcFeePercentUpdate} variant="contained" sx={{height: 55}} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Target Marketcap" value={targetMarketcap} onChange={(e) => setTargetMarketcap(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTargetMarketcapUpdate} variant="contained" sx={{height: 55}} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Target LP Amount" value={targetLPAmount} onChange={(e) => setTargetLPAmount(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTargetLPUpdate}  variant="contained" sx={{height: 55}} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Total supply" value={totlaSupply} onChange={(e) => setTotalSupply(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTotalSupplyUpdate} variant="contained" sx={{height: 55}} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Initial Amount" value={initialAmount} onChange={(e) => setInitialAmount(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcInitialAmountUpdate} variant="contained" sx={{height: 55}} fullWidth>Update</Button></Grid2>
                    </Grid2>
                </Container>
            }
        </Box>
    )
}

export default AdminPage;