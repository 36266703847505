import { Avatar, Box, Card, CardContent, Container, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ETHIcon from '../../assets/images/coin/eth.png';
import LanguageIcon from '@mui/icons-material/Language';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import { Link } from "react-router-dom";

export default function MainCard({ image, title, ticker, market_cap, trades, deployer, web, telegram, twitter, network }: { image: string, title: string, ticker: string, market_cap: number, trades: number, deployer: string, web?:string, telegram?:string, twitter?:string, network?:string }) {

    return (
        <Card sx={{ mb: '1rem' }} style={{backgroundColor: "rgba(0,0,0,46)"}}>
            <CardContent>
                <Container>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={6} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            {image === "" ? (
                                <Box sx={{ width: { sm: 200, xs: 120 }, height: { sm: 200, xs: 120 }, margin: 'auto', }}>
                                    <Skeleton variant="circular" width='100%' height='100%' />
                                </Box>
                            ) : (
                                <Box sx={{ width: { sm: 200, xs: 120 }, height: { sm: 200, xs: 120 }, margin: 'auto', }}>
                                    <img src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${image}`} width="100%" height="100%" alt="image" />
                                </Box>
                            )}

                        </Grid2>
                        <Grid2 xs={6} sm={6}>
                            <Typography sx={{ color: '#FFA800', fontSize: { sm: 24, xs: 20 }, fontFamily: "JosefinSans" }}>{title}</Typography>
                            <Typography sx={{ color: '#FF0089', fontSize: { sm: 20, xs: 14 }, fontFamily: 'JosefinSans' }}>{ticker}</Typography>
                            <Typography sx={{ color: '#00FFF0', fontSize: { sm: 20, xs: 16 }, fontWeight: 'medium' }}>Market Cap ${market_cap > 1000 ? `${market_cap/1000}k`: market_cap}</Typography>
                            <Typography sx={{ color: '#FFFFFF', fontSize: { sm: 20, xs: 16 }, fontWeight: 'medium' }}>Replies: {trades}</Typography>
                            <Box display='flex' alignItems='center'>
                                <Typography sx={{ color: '#FFFFFF', fontSize: { sm: 20, xs: 16 }, fontWeight: 'medium' }}>Deployed by</Typography>
                                <Avatar src={`https://maroon-defiant-badger-629.mypinata.cloud/ipfs/${image}`} sx={{ width: 25, height: 25, ml: '1rem' }} />
                            </Box>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Box>
                                    {(web !== "" && web !== null) && <a target="_blank" href={`${web}`}><IconButton><LanguageIcon /></IconButton></a>}
                                    {(telegram !== "" && telegram !== null) && <a target="_blank" href={`${telegram}`}><IconButton><TelegramIcon /></IconButton></a>}
                                    {(twitter !== "" && twitter !== null) && <a target="_blank" href={`${twitter}`}><IconButton><XIcon /></IconButton></a>}                                    
                                </Box>
                                <IconButton><Avatar src={`/networks/${network}.svg`} sx={{ width: 30, height: 30 }} /></IconButton>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Container>
            </CardContent>
        </Card>
    )
}