import NewCurvsIcon from '../assets/images/new.svg';
import WalletIcon from '../assets/images/wallet.svg';
import DeployIcon from '../assets/images/deploy.svg'

export const MainRouter = {
    main: [
        {title: "New Curvs", icon: NewCurvsIcon,  path: "/"},
        {title: "My Curvs", icon: WalletIcon, path: "/my_curvs"},
        {title: "Deploy a Curv", icon: DeployIcon, path: "/create_curvs"},
    ],
}